@import 'styles/utils/shared';
@import 'styles/utils/mixins';

.container {
  @media screen and (min-width: $breakpoint-sm) {
    @include grid;
    @include container;
  }
}

.title {
  @media screen and (min-width: $breakpoint-sm) {
    grid-column: 1 / span 6;
  }
}

.cardsContainer {
  overflow: scroll;
  @include hide-scrollbar;
  margin-top: var(--space-size-3);
  display: grid;
  grid-template-columns: repeat(4, minmax(15.2rem, 1fr));
  grid-template-rows: var(--space-size-2) minmax(26.1rem, 1fr) var(--space-size-2);
  grid-column-gap: var(--space-size-1-5);
  padding: 0rem 2rem;

  @media screen and (min-width: $breakpoint-sm) {
    padding: 0rem;
    grid-column: 1 / span 6;
    overflow: hidden;
  }

  @media screen and (min-width: $breakpoint-md) {
    @include grid;
    grid-column: 1 / span 12;
    grid-template-rows: var(--space-size-4) minmax(52rem, 1fr) var(--space-size-4);
  }
}

.card {
  grid-column: span 1;
  aspect-ratio: 0.58;

  &:nth-of-type(even) {
    grid-row: 1 / span 2;
  }

  &:nth-of-type(odd) {
    grid-row: 2 / span 2;
  }

  @media screen and (min-width: $breakpoint-md) {
    grid-column: span 3;
  }
}
