@import 'styles/utils/mixins';
@import 'styles/utils/shared';

.cardsContainer {
  margin: var(--space-size-2) 0rem 0rem 0rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
    margin: var(--space-size-4) 0rem 0rem 0rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    @include grid;
    align-items: center;
    margin: var(--space-size-2) 0rem 0rem 0rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin: var(--space-size-3) 0rem 0rem 0rem;
  }
}

.mainCard {
  min-height: 44.6rem;
  height: fit-content;
  max-width: calc(100% - 4rem);
  width: 100%;
  margin: 0rem auto var(--space-size-2) auto;

  @media screen and (min-width: $breakpoint-sm) {
    margin: 0rem var(--space-size-2) 0rem 0rem;
    min-height: 47.7rem;
    max-width: unset;
    min-width: 34.4rem;
  }

  @media screen and (min-width: $breakpoint-md) {
    grid-column: 2 / span 4;
    max-width: unset;
    min-width: unset;
    min-height: unset;
    margin: 0rem;
  }
  @media screen and (min-width: $breakpoint-lg) {
    width: 85%;
    margin: 0 auto;
  }
}

.cardListContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow-x: scroll;
  margin: var(--space-size-3) 0rem 0rem 0rem;
  padding: 0rem;
  height: 100%;
  @include hide-scrollbar;

  @media screen and (min-width: $breakpoint-sm) {
    margin: auto;
    height: auto;
  }

  @media screen and (min-width: $breakpoint-md) {
    grid-column: 7 / span 6;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, minmax(23rem, auto));
    column-gap: var(--space-size-2);
    row-gap: var(--space-size-3);
    overflow: hidden;
    align-items: start;
    height: 100%;
  }

  @media screen and (min-width: $breakpoint-lg) {
    grid-column: 7 / span 5;
    grid-template-rows: repeat(2, minmax(29rem, auto));
    column-gap: var(--space-size-3);
  }

  @media screen and (min-width: $breakpoint-xl) {
    grid-template-rows: repeat(2, minmax(40.3rem, auto));
  }
}

.card {
  margin-right: 1rem;
  width: fit-content;
  height: auto;

  &:first-of-type {
    margin-left: 2rem;
  }

  &:last-of-type {
    margin-right: calc(var(--space-size-2) + var(--space-size-0-5));

    @media screen and (min-width: $breakpoint-sm) {
      margin-right: var(--space-size-4);
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-right: 0rem;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    min-width: 22.4rem;

    &:first-of-type {
      margin-left: 0rem;
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    grid-column: span 2;
    grid-row: span 1;
    margin: 0rem;
    min-width: unset;
    width: 100%;

    &:first-of-type {
      margin-left: 0rem;
    }
  }
}

.mainJackpot {
  font-size: 1.6rem;
  line-height: 1.2rem;
  padding: var(--space-size-1-5) 0;

  @media screen and (min-width: $breakpoint-lg) {
    font-size: 2.4rem;
    line-height: 2rem;
  }
}
