@import 'styles/utils/shared';
@import 'styles/core/typography';
@import 'styles/core/buttons';

.container {
  border-radius: 0.8rem;
  z-index: 0;
  flex: 1 0 15.2rem;
  min-height: 26.1rem;
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    flex: 1 0 33rem;
    min-height: 52rem;
  }
}

.image {
  border-radius: 0.8rem;
  object-fit: cover;
  position: absolute;
  left: 0rem;
  top: 0rem;
}

.link {
  @include button-primary-medium;
  position: absolute;
  bottom: var(--space-size-2);
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-self: center;

  @media screen and (min-width: $breakpoint-lg) {
    @include button-primary-normal;
    bottom: var(--space-size-5);
  }
}

.label {
  color: var(--color-on-bg-primary);
  @include label-s-bold;
  white-space: nowrap;
  z-index: 2;
  background-color: var(--color-surface-01);
  border-radius: 0rem 0rem 0.4rem 0.4rem;
  transform: translate(-50%, 0%);
  top: 0;
  left: 50%;
  padding: var(--space-size-1) var(--space-size-2);
  position: absolute;
  text-transform: uppercase;

  @media screen and (min-width: $breakpoint-lg) {
    @include label-m-bold;
  }
}
