@import 'styles/core/typography';
@import 'styles/utils/mixins';

.container {
  display: flex;
  flex-direction: column;
  padding: 2.8rem 0;
  @media screen and (min-width: $breakpoint-md) {
    @include grid;
    padding: 0rem;
    min-height: 16.8rem;
    align-items: center;
  }
}

.heading {
  margin-bottom: 1.6rem;
  padding: 0 1.8rem;
  @media screen and (min-width: $breakpoint-md) {
    grid-column: 2 / span 3;
    margin-bottom: 0rem;
    padding: 0;
  }
}

.headingTitle {
  color: var(--color-primary);
  text-transform: uppercase;
  @include heading-s-bold;
}

.headingSubtitle {
  color: var(--color-on-bg-secondary);
  @include body-medium;
}

.cardsContainer {
  display: flex;
  width: 100%;
  overflow-x: scroll;
  @include hide-scrollbar;
  padding: 0 1.8rem;
  column-gap: 2rem;
  @media screen and (min-width: $breakpoint-md) {
    overflow-x: visible;
    grid-column: 5 / span 8;
    flex-direction: row;
    justify-content: center;
    column-gap: 2.8rem;
  }
}

.skeleton {
  width: 100%;
  height: 23.2rem;
}
