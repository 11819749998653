@import 'styles/utils/shared';
.container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  @media screen and (min-width: $breakpoint-sm) {
    flex-direction: row;
  }
}
