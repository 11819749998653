@import 'styles/utils/shared';

.container {
  margin-top: var(--space-size-2);
  position: relative;

  @media screen and (min-width: $breakpoint-lg) {
    margin-top: var(--space-size-4);
  }
}

.container :global(.swiper-wrapper) {
  align-items: center;
}

.container :global(.swiper-slide) {
  position: relative;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  overflow: hidden;
  width: 15.2rem;
  height: 25.4rem;
  @media screen and (min-width: $breakpoint-lg) {
    width: 33rem;
    height: 46rem;
  }

  .jackpot {
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: var(--space-size-1) 0;

    @media screen and (min-width: $breakpoint-lg) {
      font-size: 1.6rem;
      line-height: 1.2rem;
      padding: var(--space-size-1-5) 0;
    }
  }
}

.container :global(.swiper-slide-active) {
  width: 20.8rem;
  height: 32.2rem;

  @media screen and (min-width: $breakpoint-lg) {
    width: 41.2rem;
    height: 59.6rem;
    min-height: 100%;
  }

  .jackpot {
    font-size: 1.6rem;
    line-height: 1.2rem;
    padding: var(--space-size-1-5) 0;

    @media screen and (min-width: $breakpoint-lg) {
      font-size: 2.4rem;
      line-height: 2rem;
    }
  }
}

.container :global(.swiper) {
  overflow-y: visible;
  overflow-x: clip;
  height: 32.2rem;
  @media screen and (min-width: $breakpoint-lg) {
    height: 59.6rem;
  }
}

.navigationContainer {
  display: none;

  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
    flex-wrap: nowrap;
    position: absolute;
    right: var(--space-size-5);
    top: -6.4rem;
  }
}

.container :global(.swiper-wrapper.disabled) {
  transform: translate3d(4rem, 0, 0) !important;
}
.container :global(.swiper > .swiper-button-prev) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -1.8rem;
    right: 7.5rem;
    left: auto;
    z-index: 2;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -4rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.6 6L6.6 -6.11959e-08L8 1.4L3.4 6L8 10.6L6.6 12L0.6 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
}

.container :global(.swiper > .swiper-button-next) {
  display: none;

  @media screen and (min-width: $breakpoint-md) {
    display: flex;
    width: var(--space-size-3);
    height: var(--space-size-3);
    border-radius: 50%;
    border: 0.2rem solid var(--color-primary);
    position: absolute;
    top: -1.8rem;
    right: 3.6rem;
    left: auto;
  }

  @media screen and (min-width: $breakpoint-lg) {
    top: -4rem;
  }

  &::after {
    content: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.4 6L1.4 12L-6.1196e-08 10.6L4.6 6L-4.63341e-07 1.4L1.4 -6.11959e-08L7.4 6Z' fill='%23E96105'/%3E%3C/svg%3E%0A");
    font-size: unset;
  }
}
