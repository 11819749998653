@import 'styles/core/typography';
@import 'styles/core/buttons';
.card {
  padding: 3.2rem 2.8rem 2.4rem;
  height: 100%;
  position: relative;
  background-color: var(--color-surface-01);
  border: 0.18rem solid var(--color-divider-primary);
  border-radius: var(--space-size-1);
  min-height: 28rem;
  flex: 1 0 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 1.6rem;
  padding: 2.4rem 0rem 0rem;
  @media screen and (min-width: $breakpoint-sm) {
    padding: 3.2rem 2.8rem 2.4rem;
    min-height: 22.4rem;
    flex: 0 0 48%;
    margin-bottom: 2.4rem;
  }
  &:nth-child(odd) {
    @media screen and (min-width: $breakpoint-sm) {
      margin-right: 2.4rem;
    }
  }
}
.title {
  text-align: center;
  @media screen and (min-width: $breakpoint-sm) {
    text-align: left;
  }
  @include heading-m;
}
.cta {
  @include button-tertiary-medium;
  cursor: pointer;
  position: absolute;
  bottom: 2.4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  @media screen and (min-width: $breakpoint-sm) {
    transform: translateX(0%);
    left: 3.2rem;
  }
}
.image {
  object-fit: cover;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: var(--space-size-1);
  bottom: 0;
}
