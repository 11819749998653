@import 'styles/core/typography';
@import 'styles/core/buttons';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 2rem;
  height: 1.9rem;
  margin-bottom: var(--space-size-2);
}

.text {
  @include body-medium;
  margin: 0 0 var(--space-size-3);

  @media screen and (min-width: $breakpoint-md) {
    margin: 0 0 var(--space-size-4);
  }
}

.button {
  @include button-secondary-small;
  cursor: pointer;
}
.link {
  @include button-secondary-small;
  cursor: pointer;
  @include label-s-bold;
}
